<template>
  <div v-if="!isFromDocument">

    <div
      :class="data && data.validations.vertical_line ? 'vertical-line' : 'horizontal'"
      :style="getStyle"
    ></div>
    <span
      v-if="isActive"
      class="setting-icon"
      @click="openSettings"
    >
      <i class="el-icon-s-tools" />
    </span>

  </div>
  <div v-else>
    <div
      class="horizontal"
      :style="getStyle"
    > </div>
  </div>
</template>

<script>
// import {bus} from '../../../main';
export default {
  name: "HorizontalLineView",
  props: ["field", "data", "isActive", "isFromDocument"],
  data() {
    return {
      isHorizontal: ""
    };
  },
  mounted() {
    console.log("kkkk", this.data.styles, this.data, this.field);
  },
  computed: {
    getStyle() {
      if (!this.data.validations.vertical_line) {
        return `border-bottom:${
          this.data && this.data.styles && this.data.styles.line_size
            ? this.data.styles.line_size
            : 4
        }px solid ${
          this.data && this.data.styles && this.data.styles.line_color
            ? this.data.styles.line_color
            : "#00000"
        };width:${this.data.width}px;`;
      }
      if (this.data.validations.vertical_line) {
        return `border-left:${
          this.data && this.data.styles && this.data.styles.line_size
            ? this.data.styles.line_size
            : 4
        }px solid ${
          this.data && this.data.styles && this.data.styles.line_color
            ? this.data.styles.line_color
            : "#00000"
        };width:20px;height:${this.data.height}px;`;
      } else {
        return this.data.validations.vertical_line
          ? `border-left:${4}px solid #000000`
          : `border-bottom:${4}px solid #000000`;
      }
    }
  },
  methods: {
    openSettings() {
      this.$emit("settings");
    }
  }
};
</script>

<style lang="scss">
.vertical-line {
  border-left: 1px solid black;
  //  border-left: 1px solid black; /* Change the color and width as desired */
  // height: 100%; /* Change the height as desired */
}
.horizontal:after {
  content: " ";
  display: block;
  border-bottom: 0.1px solid;
}
</style>